// import React from "react";
// import ProductDescription from "./ProductDescription";

// const productData = {
//   name: "Organic Microgreens - Broccoli",
//   category: "Microgreens",
//   price: 199,
//   image: "https://thumbs.dreamstime.com/b/microgreens-sprouts-healthy-fresh-microgreens-sprouts-healthy-fresh-food-169093088.jpg",
//   description:
//     "Fresh and organic broccoli microgreens, rich in Vitamin C and Sulforaphane. Ideal for salads, smoothies, and garnishes.",
//   attributes: [
//     { name: "Weight", value: "50g" },
//     { name: "Shelf Life", value: "7 Days" },
//     { name: "Storage", value: "Keep Refrigerated" },
//   ],
// };

// const App = () => {
//   return (
//     <div>
//       <ProductDescription product={productData} />
//     </div>
//   );
// };

// export default App;

import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import BreadcrumbNavigation from "../BreadcrumbNavigation";

const ProductDescription = ({ product }) => {
  const navigate = useNavigate();

  const breadcrumbItems = [
    { label: "Home", path: "/" },
    // { label: 'Shop', path: '/shop' },
    // { label: 'Microgreens', path: '/shop/microgreens' },
    // { label: 'Details', path: '/shop/microgreens/details' },
  ];

  const handleNavigate = (path) => {
    console.log("Navigating to:", path);
    navigate(path);
    // Use react-router's `useNavigate` or another navigation logic here
    // Example: navigate(path) if using `useNavigate` hook
  };
  return (
    <>
      <BreadcrumbNavigation
        items={breadcrumbItems}
        onNavigate={handleNavigate}
      />

      <Container>
        <ImageWrapper>
          <Image src={product?.image} alt={product.name} />
        </ImageWrapper>
        <Content>
          <Title>{product.name}</Title>
         
              <div className="hl05eU">
              {/* <Price>{product.price}</Price> */}
                <div className="yRaY8j CxhGGd">₹ {product.price}</div>
                <div className="yRaY8j textdec">₹799</div>
                <div className="yRaY8k">
                  <span>70% off</span>
                </div>
              </div>

          <Description>{product.description}</Description>

          <ButtonContainer>
            <AddToCartButton onClick={() => navigate(`/customerInfoForm`)}>
              ADD TO CART
            </AddToCartButton>
            <BuyNowButton onClick={() => navigate(`/customerInfoForm`)}>
              BUY NOW
            </BuyNowButton>
          </ButtonContainer>
        </Content>
      </Container>
    </>
  );
};

// Sample product data (replace with API data)
ProductDescription.defaultProps = {
  product: {
    name: "Sample Product",
    price: "$99.99",
    description:
      "This is a sample product description. It highlights the features and benefits of the product.",
    image:
      "https://thumbs.dreamstime.com/b/microgreens-sprouts-healthy-fresh-microgreens-sprouts-healthy-fresh-food-169093088.jpg",
  },
};

export default ProductDescription;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  // @media (min-width: 768px) {
  //   flex-direction: row;
  // }
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
`;

const Content = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const Price = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #28a745;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const AddToCartButton = styled.button`
  margin-left: 10px;
  padding: 18px 8px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  width: 30vh;

  border: none;
  float: left;
  background: #ff9f00;
  color: #fff;

  //   border: none;
  //   border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #013927;
  }

  @media (min-width: 768px) {
    padding: 12px 30px;
    font-size: 1.2rem;
  }
`;

const BuyNowButton = styled.button`
  margin-left: 10px;
  padding: 18px 8px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  width: 30vh;
  border: none;
  float: left;
  background: #fb641b;
  color: #fff;

  //   border: none;
  //   border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #013927;
  }

  @media (min-width: 768px) {
    padding: 12px 30px;
    font-size: 1.2rem;
  }
`;
