import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navigation.css';

const Navigation = () => {
  return (
    <nav className="navigation">
      <ul className="nav-links">
        <li>
          <NavLink to="/" exact="true" activeclassname="active-link">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/products" activeclassname="active-link">
            Products
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" activeclassname="active-link">
            About
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" activeclassname="active-link">
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
