import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BreadcrumbNavigation from './BreadcrumbNavigation'
const CustomerInfoForm = ({ onSubmit }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    additionalNotes: '',
  });

  const [errors, setErrors] = useState({});


  const breadcrumbItems = [
    { label: 'Home', path: '/' },
     { label: 'Details', path: -1},
    // { label: 'Microgreens', path: '/shop/microgreens' },
    // { label: 'Details', path: '/shop/microgreens/details' },
  ];

  
    const handleNavigate = (path) => {
      console.log('Navigating to:', path);
      navigate(path)
      // Use react-router's `useNavigate` or another navigation logic here
      // Example: navigate(path) if using `useNavigate` hook
    };


  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    onSubmit(formData); // Send data to parent component or API
  };

  // Validate form data
  const validateForm = (data) => {
    const errors = {};
    if (!data.name) errors.name = 'Name is required';
    if (!data.email || !/\S+@\S+\.\S+/.test(data.email)) errors.email = 'Valid email is required';
    if (!data.phone || !/^\d{10}$/.test(data.phone)) errors.phone = 'Valid 10-digit phone number is required';
    if (!data.address) errors.address = 'Address is required';
    return errors;
  };

  return (
    <>
                            <BreadcrumbNavigation items={breadcrumbItems} onNavigate={handleNavigate} />

        <FormContainer>

      <h2>Customer Information</h2>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="name">Full Name</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your full name"
          />
          {errors.name && <Error>{errors.name}</Error>}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email address"
          />
          {errors.email && <Error>{errors.email}</Error>}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="phone">Phone</Label>
          <Input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Enter your phone number"
          />
          {errors.phone && <Error>{errors.phone}</Error>}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="address">Shipping Address</Label>
          <Textarea
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="Enter your shipping address"
          />
          {errors.address && <Error>{errors.address}</Error>}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="additionalNotes">Additional Notes (Optional)</Label>
          <Textarea
            id="additionalNotes"
            name="additionalNotes"
            value={formData.additionalNotes}
            onChange={handleChange}
            placeholder="Add any special instructions"
          />
        </FormGroup>

        <SubmitButton type="submit">Place Order</SubmitButton>
      </form>
    </FormContainer>
    </>

  );
};

export default CustomerInfoForm;

// Styled Components
const FormContainer = styled.div`
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const Textarea = styled.textarea`
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  resize: vertical;
`;

const SubmitButton = styled.button`
  width: 90%;
  padding: 12px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const Error = styled.span`
  color: red;
  font-size: 0.9rem;
`;

