import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BreadcrumbNavigation = ({ items, onNavigate }) => {
  return (
    <BreadcrumbContainer>
      {items.map((item, index) => (
        <BreadcrumbItem key={item.path}>
          <BreadcrumbLink
            href={item.path}
            onClick={(e) => {
              e.preventDefault();
              onNavigate(item.path);
            }}
          >
            {item.label}
          </BreadcrumbLink>
          {index < items.length - 1 && <Separator>/</Separator>}
        </BreadcrumbItem>
      ))}
    </BreadcrumbContainer>
  );
};

BreadcrumbNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default BreadcrumbNavigation;

// Styled Components
const BreadcrumbContainer = styled.nav`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #6c757d;
`;

const BreadcrumbItem = styled.span`
  display: flex;
  align-items: center;
`;

const BreadcrumbLink = styled.a`
  text-decoration: none;
  color: #007bff;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const Separator = styled.span`
  margin: 0 8px;
  color: #6c757d;
`;
