import React from 'react';
import { useNavigate } from 'react-router-dom';



// Single Product Card Component
const MicrogreenProductCard = (props) =>{
  console.log('props',props.product)

 return (
  <div className="product-card" onClick={() => props.handleProductClick(props.product.id)}>
    <div className="product-image-wrapper">
      <img src={props.product.image} alt={props.product.name} className="product-image" />
    </div>
    <div className="product-details">
      <h3 className="product-name">{props.product.name}</h3>
      <p className="product-price">₹{props.product.price.toFixed(2)}</p>
      <p className="product-description">{props.product.description}</p>
      <button className="add-to-cart-btn">Add to Cart</button>
    </div>
  </div>
)};

// Product List Component with Header
// const ProductList = ({ products }) => (
  
//   <div className="product-section">
//     {/* <h2 className="section-header">Fresh Organic Microgreens</h2> */}
//     <div className="product-list">
//       {products.map((product, index) => (
//         <MicrogreenProductCard key={index} product={product} />
//       ))}
//     </div>
//   </div>
// );

const ProductList = ({ products }) => {

  // import './MicrogreenProductCard.css';
const navigate = useNavigate();


const handleProductClick = (id) => {
  console.log('id',id)
  navigate(`/productsDetails/${id}`);
};

  return (
    <div className="product-section">
    {/* <h2 className="section-header">Fresh Organic Microgreens</h2> */}
    <div className="product-list">
      {products.map((product, index) => (
        <MicrogreenProductCard key={index} product={product} handleProductClick={handleProductClick}/>
      ))}
    </div>
  </div>
  );
};

export default ProductList;
