// import React from "react";
// import "./App.css";
// import StickyLayout from "./StickyLayout";

// import ProductList from "./ProductList";
// import MicrogreensTable from "./Microgreens";
// import ProductDescriptionS from "./ProductDescriptionS";
// import ProductNavigation from './ProductNavigation';

// function App() {
//   return (
//     <>

//     {/* <div>
//       <ProductNavigation />
//     </div> */}

//       <StickyLayout>

//         {/* <ProductNavigation/> */}

//         <ProductList products={products} />
//         {/* <ProductDescriptionS /> */}
//         {/* <MicrogreensTable /> */}
//       </StickyLayout>

//     </>
//   );
// }

// export default App;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./Navigation";
import StickyLayout from "./StickyLayout";
import ProductList from "./product/ProductList";
// import MicrogreensTable from "./Microgreens";
import ProductDescriptionS from "./product/ProductDescriptionS";
// import ProductNavigation from './ProductNavigation';
import CustomerInfoForm from "./CustomerInfoForm";

const About = () => {
  return (
    <div className="about-container">
      <h1
        className="about-title"
        title="Treelix Agritech & Industries Private Limated"
      >
        TREELIX AGRITECH AND INDUSTRIES PRIVATE LIMITED
      </h1>
      <h2>A Greener Tomorrow, Today</h2>

      <p>
        Treelix Agritech & Industries Private Limated is a pioneering force in
        the realm of sustainable agriculture, dedicated to cultivating a greener
        future. With a strong focus on indoor and outdoor plants, fruits,
        vegetables, and innovative micro-greens, we strive to nourish both
        individuals and the planet.
      </p>

      <h3>Our Vision</h3>
      <p>
        Our vision is to foster a healthier and more sustainable India. By
        promoting eco-friendly practices and reducing plastic waste, we aim to
        make a positive impact on the environment.
      </p>
      <h3>Our Mission</h3>
      <p>
        <b>Cultivate Excellence: </b> To produce high-quality, organic produce
        that nourishes both body and soul.
        <br />
        <b>Innovate for Sustainability: </b> To explore innovative agricultural
        techniques that minimize environmental impact.
        <br />
        <b>Community Empowerment: </b> To empower communities by providing
        access to fresh, healthy food and sustainable living solutions.
        <br />
      </p>
      <h3>Our Commitment</h3>
      <b>We are committed to:</b>
      <p>
        <b>Sustainability: </b> Adopting sustainable farming practices to
        preserve natural resources.
        <br />
        <b>Quality: </b>
        Delivering premium-quality products that meet the highest standards.
        <br />
        <b> Customer Satisfaction: </b> Providing exceptional customer service
        and building long-lasting relationships.
      </p>

      <p>
        <h3>
          <b>Our Products</b>
        </h3>
        <b>Micro-Greens: </b>
        A powerhouse of nutrients, our micro-greens are grown in controlled
        environments to maximize flavor and nutritional value.
        <br />
        <b>Indoor and Outdoor Plants: </b>
        A wide range of plants to beautify your spaces and purify the air.{" "}
        <br />
        <b>Fruits and Vegetables: </b> Fresh, organic produce, sourced locally
        and sustainably.
      </p>
      <h3>
        <b>Our Future</b>
      </h3>
      <p>
        As we continue to grow, we are excited to expand our operations to
        Mumbai, Bengaluru, Kolkata, Ranchi, and Delhi. By reaching a wider
        audience, we can inspire more people to embrace sustainable living and
        contribute to a greener planet.
      </p>

      <p>Join us on our journey to a healthier, greener future.</p>
      <div className="contact-info">
        <div className="">
          <img
            src="/new-removebg-preview.png" // Replace with your profile image
            alt="treelixlogo"
            className="profile-pic aboutlogo"
          />
          {/* <span className="profile-name">Treelix</span> */}
        </div>
        <p> Aparna Pathak</p>
        <p> aparna@treelix.in</p>
      </div>
    </div>
  );
  {
    /* A Greener Tomorrow, Today

TREELIX AGRITECH AND INDUSTRIES PRIVATE LIMITED is a pioneering force in the realm of sustainable agriculture, dedicated to cultivating a greener future. With a strong focus on indoor and outdoor plants, fruits, vegetables, and innovative micro-greens, we strive to nourish both individuals and the planet.

Our Vision

Our vision is to foster a healthier and more sustainable India. By promoting eco-friendly practices and reducing plastic waste, we aim to make a positive impact on the environment.

Our Mission

Cultivate Excellence: To produce high-quality, organic produce that nourishes both body and soul.
Innovate for Sustainability: To explore innovative agricultural techniques that minimize environmental impact.
Community Empowerment: To empower communities by providing access to fresh, healthy food and sustainable living solutions.
Our Commitment

We are committed to:

Sustainability: Adopting sustainable farming practices to preserve natural resources.
Quality: Delivering premium-quality products that meet the highest standards.
Customer Satisfaction: Providing exceptional customer service and building long-lasting relationships.
Our Products

Micro-Greens: A powerhouse of nutrients, our micro-greens are grown in controlled environments to maximize flavor and nutritional value.
Indoor and Outdoor Plants: A wide range of plants to beautify your spaces and purify the air.
Fruits and Vegetables: Fresh, organic produce, sourced locally and sustainably.
Our Future

As we continue to grow, we are excited to expand our operations to Mumbai, Bengaluru, Kolkata, Ranchi, and Delhi. By reaching a wider audience, we can inspire more people to embrace sustainable living and contribute to a greener planet.

Join us on our journey to a healthier, greener future.

[Your Company Logo]
[Your Contact Information]
[Your Website]
  </> */
  }
};

const products = [
  {
    id: 1,
    name: "Organic Sunflower Microgreens",
    price: 199.99,
    description: "Nutritious sunflower microgreens, perfect for salads.",
    image:
      "https://thumbs.dreamstime.com/b/microgreens-sprouts-healthy-fresh-microgreens-sprouts-healthy-fresh-food-169093088.jpg",
  },
  {
    id: 2,
    name: "Pea Shoot Microgreens",
    price: 149.99,
    description: "Crisp pea shoots for a fresh crunch.",
    image:
      "https://www.microgreen-shop.com/wp-content/uploads/2021/08/Design-ohne-Titel-20.png",
  },
  {
    id: 3,
    name: "Radish Microgreens",
    price: 129.99,
    description: "Spicy and colorful radish microgreens.",
    image:
      "https://t4.ftcdn.net/jpg/03/22/08/87/360_F_322088708_evZX37aKyhdcgBX71ld4ljLjfIomIGDn.jpg",
  },
  {
    id: 4,
    name: "Organic Sunflower Microgreens",
    price: 199.99,
    description: "Nutritious sunflower microgreens, perfect for salads.",
    image:
      "https://thumbs.dreamstime.com/b/microgreens-sprouts-healthy-fresh-microgreens-sprouts-healthy-fresh-food-169093088.jpg",
  },
  // Add more products as needed
];

const breadcrumbItems = [
  { label: "Home", path: "/" },
  { label: "Shop", path: "/shop" },
  { label: "Microgreens", path: "/shop/microgreens" },
  { label: "Details", path: "/shop/microgreens/details" },
];
const App = () => {
  const handleNavigate = (path) => {
    console.log("Navigating to:", path);
    // Use react-router's `useNavigate` or another navigation logic here
    // Example: navigate(path) if using `useNavigate` hook
  };
  return (
    <Router>
      <StickyLayout>
        <div>
          <Routes>
            <Route path="/" element={<ProductList products={products} />} />
            <Route
              path="/products"
              element={<ProductList products={products} />}
            />
            <Route
              path="/productsDetails/:id"
              element={<ProductDescriptionS />}
            />
            <Route path="/customerInfoForm" element={<CustomerInfoForm />} />

            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<CustomerInfoForm />} />
          </Routes>
        </div>
      </StickyLayout>
    </Router>
  );
};

export default App;
